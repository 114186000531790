import Axios from '@/axios'

// 员工评分列表
export const staffScoreList = function (cond = {}) {
  return Axios.post('/xcStaffScoreRatio/staffScoreList', cond)
}

// 添加员工评分
export const addOrUpdateScore = function (cond = {}) {
  return Axios.post('/xcStaffScoreRatio/addOrUpdateScore', cond)
}

// 删除员工评分
export const deleteScoreRatio = function (id) {
  return Axios.post(`/xcStaffScoreRatio/delete/${id}`)
}

// 员工工时汇总列表
export const staffManhourSummaryList = function (cond = {}) {
  return Axios.post('/xcStaffScoreRatio/staffManhourSummaryList', cond)
}

// 更新工时
export const updateManhourData = function (cond = {}) {
  return Axios.post('/xcStaffScoreRatio/updateManhourData', cond)
}

// 员工薪酬列表
export const staffVariableSalaryList = function (cond = {}) {
  return Axios.post('/staffVariableSalary/list', cond)
}

// 员工工时明细列表
export const staffManhourDetailList = function (cond = {}) {
  return Axios.post('/xcStaffScoreRatio/staffManhourDetailList', cond)
}

// 提交所有评分
export const submitAllScore = function (cond = {}) {
  return Axios.post('/xcStaffScoreRatio/submitAllScore', cond)
}

// 员工薪酬汇总列表
export const staffSalarySummarylist = function (cond = {}) {
  return Axios.post('/staffVariableSalary/staffSalarySummarylist', cond)
}

// 员工工时详情
export const staffManhourDetail = function (cond = {}) {
  return Axios.post('/xcStaffScoreRatio/staffManhourDetail', cond)
}

// 员工评分详情
export const staffScoreDetail = function (cond = {}) {
  return Axios.post('/xcStaffScoreRatio/staffScoreDetail', cond)
}

// 员工薪酬明细
export const staffSalaryDetail = function (cond = {}) {
  return Axios.post('/staffVariableSalary/staffSalaryDetail', cond)
}

//导出
export const exporting = function (cond) {
  return Axios({
    url: '/xcStaffScoreRatio/exportStaffScoreList',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
