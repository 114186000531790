import Axios from "@/axios";
import { Message } from "element-ui";

export const deptHeadApprovePass = function(cond = {}) {
  return Axios.post("/projectCostPlan/deptHeadApprovePass", cond);
};
export const deptHeadApproveReject = function(cond = {}) {
  return Axios.post("/projectCostPlan/deptHeadApproveReject", cond);
};
export const presidentApprovePass = function(cond = {}) {
  return Axios.post("/projectCostPlan/presidentApprovePass", cond);
};
export const presidentApproveReject = function(cond = {}) {
  return Axios.post("/projectCostPlan/presidentApproveReject", cond);
};
// export const reject = function(cond = {}) {
//   return Axios.post("/projectCostPlan/reject", cond);
// };
// 计划经营部通过
export const jhjyApprovePass = function(cond = {}) {
  return Axios.post("/projectCostPlan/jhjyApprovePass", cond);
};
// 计划经营部驳回
export const jhjyApproveReject = function(cond = {}) {
  return Axios.post("/projectCostPlan/jhjyApproveReject", cond);
};

// 项目成本方案通过
export const approvePass = function(cond = {}) {
  return Axios.post("/projectCostPlan/approvePass", cond);
};

// 项目成本方案驳回
export const approveReject = function(cond = {}) {
  return Axios.post("/projectCostPlan/approveReject", cond);
};
