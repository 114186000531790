import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

// 根据用户id查找角色(获取关联信息)
export const findRoleByUserId = function (cond) {
  return Axios.post('/sysStaff/findRoleByUserId/' + cond)
}
//关联角色
export const associationStaffRole = function (cond) {
  return Axios.post('/sysStaff/associationStaffRole', cond)
}

// 用户管理列表
export const listStaff = function (cond = {}) {
  return Axios.post('/sysStaff/listStaff', cond)
}

// 用户详情
export const listStaffNotPage = function (cond) {
  return Axios.post('/sysStaff/listStaffNotPage', cond)
}

// 用户列表
export const listUserInfo = function (cond = {}) {
  return Axios.post('/rsUserInfoController/listUserInfo', cond)
}

//用户编辑
export const edit = function (cond = {}) {
  return Axios.post('/rsUserInfoController/edit', cond)
}

//用户删除附件
export const del = function (cond) {
  return Axios.post('/sysStaff/delete/' + cond)
}

//用户添加
export const add = function (cond = {}) {
  return Axios.post('/rsUserInfoController/add', cond)
}

//用户操作日志
export const listLogininfor = function (cond = {}) {
  return Axios.post('/sysOperLog/listLogininfor', cond)
}

//用户登录日志
export const listLoginLog = function (cond = {}) {
  return Axios.post('/sysLogininfor/listLogininfor', cond)
}

// 用户附件保存
export const uploadFileSave = function (cond = {}) {
  return Axios.post('/rsUserInfoController/uploadInterviewFile', cond)
}

//用户删除附件
export const deleteUserAttachment = function (cond) {
  return Axios.post('/rsUserInfoController/deleteUserAttachment/' + cond)
}

//获取用户附件列表
export const getUserAttachmentList = function (cond) {
  return Axios.post('/rsUserInfoController/getUserAttachmentList/' + cond)
}

//用户调岗
export const surveyWork = function (cond) {
  return Axios.post('/rsUserInfoController/surveyWork', cond)
}

//获取用户详细信息
export const getUserInfo = function (cond) {
  return Axios.post('/rsUserInfoController/getUserInfo/' + cond)
}

//查询签署劳动合同用户信息表
export const getLaborContractUserInfo = function (cond = {}) {
  return Axios.post('/rsUserInfoController/getLaborContractUserInfo', cond)
}

//查看历史劳动合同
export const getLaborContract = function (cond) {
  return Axios.post('/rsUserInfoController/getLaborContract/' + cond)
}

//查询转正用户信息列表
export const getPositiveUserInfo = function (cond = {}) {
  return Axios.post('/rsUserInfoController/getPositiveUserInfo', cond)
}

//转正延期
export const positiveDelay = function (cond = {}) {
  return Axios.post('/rsUserInfoController/positiveDelay', cond)
}

//添加劳动合同
export const addLaborContract = function (cond = {}) {
  return Axios.post('/rsUserInfoController/addLaborContract', cond)
}

//查看面谈附件
export const getInterviewRecordList = function (cond) {
  return Axios.post('/rsUserInfoController/getInterviewRecordList/' + cond)
}

//转正延期
export const savaUserMt = function (cond = {}) {
  return Axios.post('/rsUserInfoController/savaUserMt', cond)
}

//员工离职
export const userDimission = function (cond) {
  return Axios.post('/rsUserInfoController/userDimission/' + cond)
}

//excel批量新增薪资信息
export const addBatchRsSalaryManage = function (cond = {}) {
  return Axios.post('/rsSalaryManageController/addBatchRsSalaryManage', cond)
}

//新增薪资信息
export const addRsSalaryManage = function (cond = {}) {
  return Axios.post('/rsSalaryManageController/addRsSalaryManage', cond)
}

//导出薪资信息
export const exportSalaryManage = function (cond = {}) {
  return Axios.post('/rsSalaryManageController/exportSalaryManage', cond)
}

//查看薪资管理列表
export const getRsSalaryManageList = function (cond = {}) {
  return Axios.post('/rsSalaryManageController/getRsSalaryManageList', cond)
}

//修改薪资信息
export const updateRsSalaryManage = function (cond = {}) {
  return Axios.post('/rsSalaryManageController/updateRsSalaryManage', cond)
}

//删除薪资信息
export const deleteSalaryManage = function (cond) {
  return Axios.post('/rsSalaryManageController/deleteSalaryManage/' + cond)
}

// 人员统计报告
export const getStatisticReport = function (cond = {}) {
  return Axios.post('/rsUserInfoController/staffStatisticReport', cond)
}

// 导出人员统计
export const exportStatistic = function (cond = {}) {
  return Axios({
    url: '/rsUserInfoController/staffStatisticReportExport',
    method: 'post',
    data: cond,
    responseType: 'blob',
  })
}

// 在职人员分析
export const getStatisticAnalysis = function (cond = {}) {
  return Axios.post('/rsUserInfoController/staffStatisticAnalysis', cond)
}

// 人员异动分析
export const getPersonnelChanges = function (cond = {}) {
  return Axios.post('/rsUserInfoController/personnelChanges', cond)
}

// 招聘情况分析
export const getRecruitmentInfo = function (cond = {}) {
  return Axios.post('/rsUserInfoController/getRecruitmentInfo', cond)
}

// 新增招聘情况分析
export const addRecruitmentInfo = function (cond = {}) {
  return Axios.post('/rsUserInfoController/addRecruitmentInfo', cond)
}

// 编辑招聘情况分析
export const updateRecruitmentInfo = function (cond = {}) {
  return Axios.post('/rsUserInfoController/updateRecruitmentInfo', cond)
}

//招聘情况导出
export const exportRecruitmentInfo = function (cond = {}) {
  return Axios({
    url: 'rsUserInfoController/exportRecruitmentInfo',
    method: 'post',
    data: cond,
    responseType: 'blob',
  })
}

// 导出在职人员分析统计
export const exportAnalysis = function (cond = {}) {
  return Axios({
    url: '/rsUserInfoController/exportStaffStatisticAnalysis',
    method: 'post',
    data: cond,
    responseType: 'blob',
  })
}

// 新的查询用户列表接口
export const staffList = function (cond = {}) {
  return Axios.post('/sysStaff/staffList', cond)
}

// 管理员重置密码
export const resetPassword = function (cond = {}) {
  return Axios.post('/userLogin/resetPassword', cond)
}

// 查询所有用户（包括离职）
export const staffAllList = function (cond = {}) {
  return Axios.post('/sysStaff/staffAllList', cond)
}

//员工离职(新)
export const dimission = function (cond) {
  return Axios.post('/rsUserDepart/dimission', cond)
}

//获取标签列表
export const labelList = function (cond = {}) {
  return Axios.post('/rsLabel/list', cond)
}

//新增标签
export const addLabel = function (cond = {}) {
  return Axios.post('/rsLabel/add', cond)
}

//编辑标签
export const editLabel = function (cond = {}) {
  return Axios.post('/rsLabel/edit', cond)
}

//删除标签
export const deleteLabel = function (cond) {
  return Axios.post('/rsLabel/edit/' + cond)
}

//离职人员列表
export const departList = function (cond = {}) {
  return Axios.post('/rsUserDepart/list', cond)
}

//获取离职人员详情
export const departDeatail = function (cond) {
  return Axios.post('/rsUserDepart/getDeatail/' + cond)
}

//导出新增人员模板
export const addTemplate = function (cond) {
  return Axios({
    url: '/rsUserInfoController/addBatchTemplate',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// excel批量新增用户信息
export const addBatch = function (cond = {}) {
  return Axios.post('/rsUserInfoController/addBatch', cond)
}

//查询用户所属项目信息
export const getUserDept = function (cond) {
  return Axios.post('/rsUserInfoController/getUserDept', cond)
}

//excel批量导出用户信息
export const exportBatch = function (cond) {
  // return Axios.post("/rsUserInfoController/exportBatch");
  return Axios({
    url: '/rsUserInfoController/exportBatch',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//查询员工生日
export const getBirthday = function (cond) {
  return Axios.post('/rsUserInfoController/staffBirthday', cond)
}

//excel批量导出员工生日
export const exportBirthday = function (cond) {
  return Axios({
    url: '/rsUserInfoController/birthdayExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//获取人才库列表
export const getRecruitmentTalent = function (cond = {}) {
  return Axios.post('/rsUserInfoController/getRecruitmentTalent', cond)
}

//人才库新增
export const addRecruitTalent = function (cond = {}) {
  return Axios.post('/rsUserInfoController/addRecruitTalent', cond)
}

//人才库编辑
export const editRecruitTalent = function (cond = {}) {
  return Axios.post('/rsUserInfoController/editRecruitTalent', cond)
}

//人才库删除
export const deleteRecruitTalent = function (cond = {}) {
  return Axios.post('/rsUserInfoController/deleteRecruitTalent/' + cond)
}

//司龄透视分析表
export const staffWorkAgeReport = function (cond = {}) {
  return Axios.post('/rsUserInfoController/staffWorkAgeReport', cond)
}

//司龄透视分析表导出
export const exportStaffWorkAgeReport = function (cond) {
  return Axios({
    url: '/rsUserInfoController/staffWorkAgeReportExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//上传文件
export const addFiles = function (cond = {}) {
  return Axios.post('/rsDocument/add', cond)
}

//删除文件
export const deleteFiles = function (cond) {
  return Axios.post('/rsDocument/delete/' + cond)
}

//知识文件列表
export const documentList = function (cond = {}) {
  return Axios.post('/rsDocument/list', cond)
}

//入职文件培训列表
export const documentListEntry = function (cond = {}) {
  return Axios.post('/rsDocument/listEntry', cond)
}

//获取考勤记录表
export const attendance = function (cond) {
  return Axios.post('/rsUserInfoController/attendance', cond)
}

//考勤记录新增(批量)
export const attendanceAdd = function (cond) {
  return Axios.post('/rsUserInfoController/attendanceAdd', cond)
}

//考勤记录编辑
export const attendanceEdit = function (cond) {
  return Axios.post('/rsUserInfoController/attendanceEdit', cond)
}

//考勤记录导出
export const attendanceExport = function (cond) {
  return Axios({
    url: '/rsUserInfoController/attendanceExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//删除考勤记录
export const attendanceDelete = function (cond) {
  return Axios.post('/rsUserInfoController/attendanceDelete/' + cond)
}

//离职人员司龄透视分析表
export const staffDepartReport = function () {
  return Axios.post('/rsUserInfoController/staffDepartReport')
}

//离职人员司龄透视分析表导出
export const staffDepartReportExport = function (cond) {
  return Axios({
    url: '/rsUserInfoController/staffDepartReportExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//人员异动分析导出
export const exportPersonnelChanges = function (cond) {
  return Axios({
    url: '/rsUserInfoController/exportPersonnelChanges',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//新增招聘信息
export const recrutPlanAdd = function (cond) {
  return Axios.post('/recrutPlan/add', cond)
}

//考勤记录审批列表
export const recrutPlanAuitList = function (cond) {
  return Axios.post('/recrutPlan/auitList', cond)
}

//删除招聘信息
export const recrutPlanDelete = function (cond) {
  return Axios.post('/recrutPlan/delete/' + cond)
}

//编辑招聘信息
export const recrutPlanEdit = function (cond) {
  return Axios.post('/recrutPlan/edit', cond)
}

//考勤记录填报列表
export const recrutPlanList = function (cond) {
  return Axios.post('/recrutPlan/list', cond)
}

//董事长审批不通过
export const recrutPlanDszFailPass = function (cond) {
  return Axios.post('/recrutPlan/recrutPlanDszFailPass/' + cond)
}

//董事长审批通过
export const recrutPlanDszPass = function (cond) {
  return Axios.post('/recrutPlan/recrutPlanDszPass/' + cond)
}

//人事审批不通过
export const recrutPlanRsFailPass = function (cond) {
  return Axios.post('/recrutPlan/recrutPlanRsFailPass/' + cond)
}

//人事审批通过
export const recrutPlanRsPass = function (cond) {
  return Axios.post('/recrutPlan/recrutPlanRsPass/' + cond)
}

//撤回招聘信息
export const recrutPlanReject = function (cond) {
  return Axios.post('/recrutPlan/reject/' + cond)
}

// 查询综合部非组长成员列表
export const listZhbMemberNotLeader = function () {
  return Axios.post('/sysStaff/listZhbMemberNotLeader')
}

// 查询综合部组长成员列表
export const listZhbGroupLeader = function () {
  return Axios.post('/sysStaff/listZhbGroupLeader')
}

// 根据用户id查询用户信息，用于人员管理详情
export const selectStaffInfo = function (cond) {
  return Axios.post('/sysStaff/selectStaffInfo/' + cond)
}

// excel批量新增用户薪资
export const updateSalaryBatch = function (cond = {}) {
  return Axios.post('rsUserInfoController/updateSalaryBatch', cond)
}

// 在用户列表中新增小组管理员
export const addDeptGroupLeader = function (cond = {}) {
  return Axios.post('sysDeptGroup/add', cond)
}

// 在用户列表中编辑小组管理员
export const editDeptGroupLeader = function (cond = {}) {
  return Axios.post('sysDeptGroup/edit', cond)
}

// 在用户列表中删除小组管理员
export const deleteDeptGroupLeader = function (cond) {
  return Axios.post('sysDeptGroup/delete/' + cond)
}

// 在用户列表中查询小组管理员
export const listDeptGroupLeader = function (cond = {}) {
  return Axios.post('sysDeptGroup/listDeptGroup', cond)
}

// 获取可查看的员工
export const getStaffList = function (cond = {}) {
  return Axios.get('sysStaff/getStaffList', cond)
}